.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-newline {
    display: none !important;
}

.image-caption-input {
    margin-bottom: 0.5rem;
    width: 400px;
}

@media (max-width: 580px) {
    .image-caption-input {
        margin-bottom: 0.5rem;
        width: 250px;
    }
}

@media (min-width: 500px) {
    .annotate-image-modal > div > div#modal {
        min-width: 500px !important;
    }
}
@media (max-width: 600px) {
    .annotate-image-modal > div > div#modal {
        padding: .5rem !important;
    }
}