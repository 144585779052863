.channel-details-container {
  height: 50vh;
}

.channel-details-container .no-padding {
  padding-left: 0%;
}
.channel-details-container nav {
  justify-content: center;
}
.channel-details-container nav div {
  padding: 0;
}
.channel-details-container nav ul {
  padding: 0;
  grid-gap: 1.5rem;
}
.border-b {
 border: none
}

nav {
  justify-content: space-evenly !important;
}

nav ul {
  padding-left: 0;
}

.reference-flyout {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 3;
}
  
.reference-flyout > nav {
  justify-content: center !important;
}
