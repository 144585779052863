.orange-btn{
  background-color: #ff5000!important;
  color: #fff!important;padding: 10px 20px;
  cursor: pointer;height: 40px;font-size: 15px;
  margin-right: 0px!important;display: inline-block;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  transition: all .2s ease-in-out;
  border-radius: 0.25rem;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.num-btn {
  display: inline-block;
  background-color:#f0f0f0;
  width: 225px;
  margin: auto;
  padding: 15px;
  border-radius: 8px;
}

.design-oval {
  display: flex;
  width: 60%;
  height: 470px;
  margin: 0 auto;
  background: rgba(217, 217, 217, 0.36);
  border-radius: 60px;
  top: 216px;
  
}

.left-container {
  display: flex;
  width: 500px;
}

.teamChat-thryv-log {
  width: 300px;
  margin-left: 50px;
  margin-top: 50px;
}

.right-gif {
  display: flex;
  margin: 0 auto;
  margin-right: -150px;
}

.teamchat-header-text {
  width: 280px;
  height: 68px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  margin-left: 30px;
  margin-top: 10px;
  line-height: 34px;
  color: #4D4D4D;
  left: 436px;
  top: 344px;
  text-align: justify;
}

.teamchat-sub-header {
  width: 345px;
  height: 44px;
  left: 436px;
  top: 428px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-left: 30px;
  margin-top: 15px;
  text-align: justify;


}

.teamchat-eap {
  width: 368px;
  height: 20px;
  left: 437px;
  top: 486px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #FF5000;
  margin-left: 30px;
  margin-top: 15px;
  text-align: justify;

}

.teamchat-eap-1 {
  width: 355px;
  height: 20px;
  left: 437px;
  top: 506px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #808080;
  margin-left: 30px;
  text-align: justify;
}

.start-chatting-btn {
  display: flex;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 24px;
  gap: 8px;
  width: 180px;
  height: 45px;
  left: 435px;
  top: 538px;
  background: #FF5000;
  border-radius: 45px !important;
  margin-left: 30px;
  margin-top: 20px;
  color: #FFFFFF;
}

.teamcht-tagline {
  width: 50%;
  height: 50px;
  left: 437px;
  top: 656px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #4D4D4D;
  margin: 20px auto;
  text-align: justify;
}

.teamchat-feature {
  width: 338px;
  height: 20px;
  left: 437px;
  top: 722px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 3px;
  color: #FF5000;
  margin: 20px 390px;
  margin-top: 20px;
}

.teamchat-features-icon {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 50%;
  gap: 30px;
  margin: 0 auto;
  justify-content: center
}

.teamchat-features-icon-oval {
  width: 172px;
  height: 50px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 53px;
  display: flex;
}

.features-icon {
  width: 50px;
  margin-left: 2px;
}

.feature-name {
  width: 108px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  color: #4D4D4D;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reactivate-teamchat {
width: 500px;
height: 230px;
left: 370px;
top: 240px;
background: #FFFFFF;
box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
border-radius: 3px;
display: flex;
display: flex;
flex-direction: column;
align-items: center;
padding: 32px;
gap: 30px;
}

.deactivate-container-wrapper {
display: flex;
flex-direction: row;
grid-gap: 30px;
gap: 30px;
justify-content: center;
}

.deactivate-or-expire-heading {
width: 375px;
height: 26px;
left: -0.5px;
top: -1px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 26px;
display: flex;
align-items: center;
color: #231F20;
}

.deactivate-container-subheading {
width: 375px;
height: 50px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 25px;
color: #808080;
flex: none;
flex-grow: 0;
}

.start-chatting-flow-button {
height: 32px;
background: #FF5000;
border-radius: 4px;
flex: none;
order: 0;
flex-grow: 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 24px;
gap: 8px;
}


.activate-teamchat {
width: 500px;
height: 250px;
left: 370px;
top: 240px;
background: #FFFFFF;
box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
border-radius: 3px;
display: flex;
display: flex;
flex-direction: column;
align-items: center;
padding: 32px;
gap: 20px;
}

.activate-container-wrapper {
display: flex;
flex-direction: row;
grid-gap: 30px;
gap: 30px;
justify-content: center;
}

.activate-heading {
width: 375px;
height: 26px;
left: -0.5px;
top: -1px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 26px;
display: flex;
align-items: center;
color: #231F20;
}

.activate-container-subheading {
width: 375px;
height: 80px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 25px;
color: #808080;
flex: none;
flex-grow: 0;
}

.message-container {
width: 500px;
left: 370px;
top: 240px;
background: #FFFFFF;
box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
border-radius: 3px;
display: flex;
display: flex;
flex-direction: column;
align-items: center;
padding: 32px;
gap: 20px;
}

.message-text {
  width: 375px;
  height: 80px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #808080;
  flex: none;
  flex-grow: 0;
}

.message-wrapper-activationFlow {
display: flex;
flex-direction: row;
grid-gap: 30px;
gap: 30px;
justify-content: center;
}

.logo-activation {
display: -webkit-inline-box;
width: 300px;
margin-bottom: 10px;

}

.activation-tag-line {
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 34px;
text-align: center;
color: #000000;
margin-bottom: 10px;
}

.activation-started {
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 25px;
text-align: center;
color: #808080;
margin-bottom: 20px !important;
}

.strikediag-price {
  background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%);
}
