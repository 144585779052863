.channel-member-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 0 0.75rem 0rem;
  gap: 0.5rem;
}

.channel-member {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
}

.type-conversation {
  grid-template-columns: 1fr;
}

.channel-member-display-items {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.channel-member-email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.channel-member button {
  border: none !important;
}
