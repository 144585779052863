@media (max-width: 580px) {
    .ref-side-sheet-container {
        width: 300px;
    }
}

@media (max-width: 420px) {
    .ref-side-sheet-container {
        width: 200px;
    }
}