.Main {
  position: relative;
  display: flex;
  overflow: hidden;
}

.flyout-reference-panel {
  position: absolute;
  right: 0;
  z-index: 6;
  display: flex;
}

.max-vertical-height {
  height: 100vh;
}

.calculated-vertical-height {
  height: calc(100vh - 90px);
}

.resizable {
  max-width: 25vw;
  min-width: 18vw;
}

.Main > button {
  z-index: 10;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.8rem;
  right: 1rem;
  height: 1.8rem;
  width: 1.8rem;
  background: none;
  border: none;
  font-size: 1.5rem;
}

.Main .ChannelBrowser {
  position: relative;
  z-index: 9;
}

.flyout-reference-panel {
  display: flex;
}

@media (max-width: 31.88em) {
  .flyout-reference-panel .relative{
    width: 100% !important;
  }
  .flyout-reference-panel .relative >div {
    padding: 0.5rem !important;
  }
}
