.team-chat-progress-bar-wrapper {
    background: #FEEEE6;
    height: 5px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100%;
}

.team-chat-progress-bar-wrapper .progress-bar {
    height: 5px;
    background: #FC521F;
    width: 50%;
    transition: width 0.5s ease-in-out;
}

.team-chat-progress-bar-wrapper .progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: #FC521F;
    animation: indeterminateAnimation 400ms infinite linear;
    transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
    0% {
        transform:  translateX(0) scaleX(0);
    }
    40% {
        transform:  translateX(0) scaleX(0.4);
    }
    100% {
        transform:  translateX(100%) scaleX(0.5);
    }
}