.logged-in-results {
    width: 12.5px;
    height: 12.5px;
    border-radius: 50%;
    background-color: #16A085;
    border: 2px solid white;
    bottom: 0;
    right: 8px;
    position: absolute;
  }
  
  .logged-out-results {
    width: 12.5px;
    height: 12.5px;
    border-radius: 50%;
    background-color: #a19b9b;
    border: 2px solid white;
    bottom: 0;
    right: 8px;
    position: absolute;
  }

.search-container {
    background-color: #F8F9FB;
    width: 100vw;
}

.search-navigation {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem 1.5rem 0.1rem 1.5rem;
    border-bottom: 1.5px solid rgb(237, 237, 242);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    font-size: 0.8rem;
}

.search-header {
    padding: 0 2rem 0 0.5rem;
}

/* Remove extra bottom border created by Navigation component */
.search-navigation>nav {
    border-bottom: none !important;
}

.search-results {
    padding: 1rem 2rem;
    overflow-y: scroll;
    height: 82vh;
}

.search-results-container {
    background-color: #ffffff;
    padding: 1rem;
    border: 1px solid #EAEAEA;
}

.results-team-member {
    background-color: #ffffff;
    padding: .5rem .5rem;
    margin: .2rem;
    border: 1px solid #eeeeee;
    border-radius: 2px;
}

.results-team-member:hover {
    cursor: pointer;
    background-color: #F8F9FB
}

.users-icons-container {
    cursor: pointer;
    position: relative;
    display: none;
}

@media (max-width: 46.88em) {
    .users-icons-container {
        display: block;
    }
}

@media (max-width: 25.375em) {
    .search-navigation {
        display: flex;
        height: 3rem;
    }

    .users-icons-container {
        padding: 0 0 0 2rem;
    }
}