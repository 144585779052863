.ChatRoom {
  display: flex;
  flex-direction: column;
  width: 100vw;
  position: relative;
}

.ChatRoom .MessageList {
  flex: 1 1 auto;
  overflow: auto;
}

.ChatRoom.overlay::before {
  content: '';
  min-width: 30vw;
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 5;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

.typing-dot {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin-left: 6px;
  display: inline-block;
  background-color: #988c8c;
  animation: 1.2s typing-dot ease-in-out infinite;
  margin-top: 50%;
}

.typing-dot:nth-of-type(2) {
  animation-delay: 0.15s;
}

.typing-dot:nth-of-type(3) {
  animation-delay: 0.25s;
}

@keyframes typing-dot {
  15% {
    transform: translateY(-35%);
    opacity: 0.5;
  }
  30% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.typing_container .circle {
  width: 40px;
  height: 40px;
  background: #E2E2E2;
  border-radius: 50%;
  margin-left: 6px;
}


.typing_container
{
    text-align:left;
}

.typing_container_wrapper {
  background-color: #f8f9fb;
  min-height: 40px;
  width: 100%;
}

.circle, .typing
{
    display:inline-block;
}
