.app-header {
    height: 90px;
    padding: 13px 2px 13px 17px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    width: 100%;
    z-index: 10;
    margin-bottom: 4px;
    box-shadow: 0 3px 2px 0 rgb(0 0 0 / 10%);
}

.app-header .title {
    display: inline-flex;
    justify-content: left;
    align-items: center;
}

.app-header .user-profile {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: 1fr;
    align-items: center;
    padding: 1rem;
    background: inherit;
    cursor: pointer;
    grid-template-areas:
  "displayname avatar";
}

.app-header .user-profile .avatar {
    grid-area: avatar;
}

.app-header .user-profile .display-name {
    overflow: hidden;
    text-align: start;
    grid-area: displayname;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    margin: 0;
}

.app-header .user-options {
    position: absolute;
    background: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    right: 1rem;
    top: 4.5rem;
    z-index: 10;
    width: 250px;
    color: rgba(12,28,40,0.87);
    font-size: 12px;
}
.app-header .user-options ul {
    margin: 0;
    padding: 0;
}
.app-header .user-options li {
    padding: .5rem;
    border-top: 1px solid #EAEDF1;
    cursor: pointer;
}
.app-header .user-options li:hover {
    background: #EAEDF1;
}

@media (max-width: 580px) {
    .app-header .user-profile .display-name {
        display: none;
    }
}

.box {
    display: flex;
    width: 220px;
    height: 38px;
    border: 0.5px solid #dee6ee;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    font-family: system-ui;
}

.emoji-btn {
    font-size: 20px;
    filter: grayscale(1);
    transition: .1s;
    border: none;
    cursor: pointer;
  }

  .box:hover .emoji-btn{filter: grayscale(0)}

  .statusOptions:hover {background-color: #EAEDF1; }

  .selected{
      background-color: #EAEDF1;
      font-weight: bold;
  }

  .emojiPicker {
    z-index: 5;
    position: absolute;
    align-items: center;
    font-size: 18px;
    padding: 0;
    background: #fff;
    box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
    transform: translateX(-125%);
    max-width: 100px;
    margin-left: 120px;
  }

  .statusOptions {
    font-size: 16px;
    cursor: pointer;
    padding: 8px
  }

  .footerButtonStyle {
    display: flex;
    justify-content: end;
    gap: 25px;
    text-transform: capitalize;
    font-size: 14PX;
    font-family: Open Sans;
}

.boxInsideThryv {
    display: flex;
    height: 38px;
    border: 0.5px solid #dee6ee;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    font-family: system-ui;
}

hr {
    display: block;
    height: 1px;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
    border: 0.5px solid gray !important;
  }


  .icon-container {
    position: relative;
  }
  
  .online {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #16A085;
    border: 2px solid white;
    bottom: 0;
    right: 0;
    position: absolute;
  }
  
  .offline {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #a19b9b;
    border: 2px solid white;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  .UserPresenceOnline {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #16A085;
    border: 2px solid white;
    bottom: 0;
    right: 0;
    position: absolute;
  }
  
  .UserPresenceOffline {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #a19b9b;
    border: 2px solid white;
    bottom: 0;
    right: 0;
    position: absolute;
  }
  
.boxInsideThryv:hover .emoji-btn{filter: grayscale(0)}
