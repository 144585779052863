.icon-container {
    position: relative;
  }

.message-search {
    padding: 1rem 1rem 0 1rem;
}

.logged-in-search {
    width: 12.5px;
    height: 12.5px;
    border-radius: 50%;
    background-color: #16A085;
    border: 2px solid white;
    bottom: 0;
    right: 7.5px;
    position: absolute;
  }
  
  .logged-out-search {
    width: 12.5px;
    height: 12.5px;
    border-radius: 50%;
    background-color: #a19b9b;
    border: 2px solid white;
    bottom: 0;
    right: 8px;
    position: absolute;
  }

.search-input {
    width: 100%;
}

.search-results-popover {
    position: absolute;
    width: calc(100% - 2rem);
    max-height: 75vh;
    z-index: 10;
    margin-top: 2px;
    overflow-y: auto;
}

.search-popover-title {
    color: #BABABA;
}

.search-popover-item-date {
    color: #8D8D8D;
}

.search-popover-result:hover {
    cursor: pointer;
}

.search-avatar {
    width: 32px;
    height: 32px;
}