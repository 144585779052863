.ReactionBar {
  display: flex;
  background: #fff;
  border-radius: 4rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
}

.reactions-container:hover .reactions-senders-container {
  display: flex;
} 

.ReactionMedal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  font-size: 18px;
  padding: 0.1rem;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 4rem;
}

.ReactionMedal > svg {
  height: 0.8rem;
}

.ReactionMedal + .ReactionMedal {
  margin-left: -0.5rem;
}

.ReactionCount {
  display: flex;
  align-items: center;
  padding: 0 0.5rem 0 0.25rem;
}
