.ConversationList {
  width: 100%;
  padding: 0 0 1rem 0;
  border-top: 1px solid #ccd0d5;
}

.ConversationList.top {
  border-top: 1px solid transparent;
}

.view-more-button {
  margin-left: 1rem;
}