.icon-container {
  position: relative;
}

.logged-in-modal {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #16A085;
  border: 3px solid white;
  bottom: 5px;
  right: 5px;
  position: absolute;
}

.logged-out-modal {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #a19b9b;
  border: 3px solid white;
  bottom: 5px;
  right: 5px;
  position: absolute;
}

.TeamMemberModalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.DisplayName {
    padding-top: 1rem;
    padding-bottom: 1rem;
}