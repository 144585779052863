.content-metadata-box {
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
    "message"
    "metadata";
  padding: 0 1rem 0 1rem;
}

.icon-container {
  position: relative;
}

.logged-in-message {
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  background-color: #16A085;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}

.logged-out-message {
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  background-color: #a19b9b;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}

.message-wrapper:last-child {
  margin-bottom: 2rem;
}

.MessageItem .Avatar {
  width: 32px;
  height: 32px;
  background: transparent;
  border-radius: 2rem;
  padding-top: 4px;
}

.MessageItem .DisplayName {
  grid-area: displayname;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  padding: 24px 0 4px 18px;
}

.MessageItem .DisplayName .UserName:hover {
  text-decoration: underline;
  cursor: pointer;
}

.MessageItem .SyncState,
.MessageItem .SyncState svg {
  display: inline-block;
  color: currentColor;
  font-size: 0.7rem;
}

.MessageItem .SyncState {
  position: relative;
  top: 2px;
  opacity: 0.5;
}

.MessageItem .Content {
  display: flex;
  align-content: center;
  padding: 0.3rem;
  max-width: 32vw;
  margin: 0 0 0.25rem;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 15px 15px 15px 0px;
  grid-area: message;
  justify-self: start;
}

.MessageItem.image .Content {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: .2rem;
}

.MessageItem.image .Content .Avatar {
  grid-column: 1;
}

.MessageItem.image .Content .Image {
  grid-column: 2;
}

.MessageItem.image .Content .Text {
  grid-column: 1 / -1;
}

.MessageItem .Text {
  word-break: break-word;
}

.MessageItem .Metadata {
  grid-area: metadata;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #636878;
  font-size: 0.7rem;
  justify-content: flex-end;
}

.MessageItem .Metadata .ReactionBar {
  transform: translateY(-.6rem);
}

.MessageItem .Text,
.MessageItem .FileContent {
  font-weight: normal;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: .5rem;
  padding-left: 0.75rem;
}

.MessageItem img {
  display: block;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.MessageItem .FileContent {
  position: relative;
  padding: 0.75rem 1rem 0.75rem 0.75rem;
  color: inherit;
  text-decoration: none;
  word-break: break-word;
  display: flex;
  flex-direction: row;
  gap: .5rem;
}

.MessageItem .FileContent svg {
  width: 1.7rem;
  height: 1.7rem;
}

.MessageItem .FileContent strong {
  width: 90%;
}

/* .MessageItem.self .Avatar {
  display: none;
} */

.MessageItem.self .Content {
  box-shadow: 0 4px 2px rgba(0, 0, 0, 0.08);
  border-radius: 15px 15px 0 15px;
  background: var(--primary);
  margin-top: 0;
  justify-self: flex-end;
  /* flex-direction: column-reverse; */
}

.MessageItem.self .Text,
.MessageItem.self .FileContent {
  padding: 0.5rem;
  color: #fff;
}

/* DayMarker */
.DayMarker {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgb(237, 237, 242);
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.DayMarker span {
  font-size: 11px;
  color: #BABABA;
  background: var(--message-list-bg);
  padding:0 1.5rem;
}

.mentioned-content-self {
  font-weight: bold;
  cursor: pointer;
}

.mentioned-content-others {
  color: #2D7AFF;
  cursor: pointer;
}

.text-content-wrapper {
  font-family: Open Sans, sans-serif !important;
}

/* custom styles */
.message-wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 1rem;
  position: relative;
}

.message-wrapper.self {
  flex-direction: row;
}

.edit-delete-react {
  position: relative;
  display: flex;
  align-items: center;
  gap: .5rem;
  transform: translateY(-15px);
}

.edit-delete-react button {
  margin-bottom: .1rem;
}

.edit-delete-react svg {
  height: 15px;
}

#edit-btn {
  margin-top: .1rem;
}

.edit-delete-react.adjustYPosition {
 transform: translateY(-40.5px);
}

.message-btn {
  cursor: pointer;
}

.emoji-config {
  font-size: 24px;
  padding: 0 2px 0 2px;
}

.reactions-senders-container {
  background-color: white;
  position: absolute;
  top: 100%;
  right: 0;
  transform: translateY(-.7rem);
  padding: .2rem;
  border-radius: .5rem;
  display: none;
  flex-direction: column;
  gap: .3rem;
}

.reaction-sender {
  display: flex;
  gap: .5rem;
}

.z-index-2 {
  z-index: 2;
}

.z-index-1 {
  z-index: 1;
}

@media (max-width: 46.88em) {
  .MessageItem .Content {
    max-width: 55vw;
  }
}

@media (max-width: 26.56em) {
  .message-wrapper {
    gap: 1rem;
  }
}