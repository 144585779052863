.RefPanelTitleBar {
  background: #292A2E;
  color: #FFF;
  font-family: Montserrat;
  font-style: normal;
  margin: 0 auto;
  min-height: 58px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  border-radius: .5rem .5rem 0 0;
}

.RefPanelTitleBar .title-text {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
}
.margin-right {
  margin-right: 5px;
}

.RefPanelTitleBar .info-text {
    font-weight: 500;
    font-size: 12px;
}

.RefHighlightedText {
    color: #FFF;
    background: #4D4D4D;
    padding: 0.2rem;
}

.RefPanelLabelText {
    font-size: 12px;
    font-weight: 600;
}

.RefLabelColor {
    color: #A3A5A7;
}

.reference-panel-container .display-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
}

.RefClientListAvatar {
    height: 35px !important;
    width: 35px !important;
}

@media (max-width: 580px) {
    .RefPanelTitleBar .title-text {
        font-size: 16px;
    }
}

