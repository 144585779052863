.no-reference-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 8rem;
  padding-top: 2rem;
}

.pulse-icon {
  padding-bottom: 1rem;
}
