.MessageList {
  overflow: auto;
  background: var(--message-list-bg);
}

.MessageList .ChatNoteSection {
  padding: 1rem;
  width: 558px;
  height: 52px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
}

.HighlightedText {
  color: #FF5001;
  background: #FEEEE6;
  padding: 0.2rem;
}

.scroll-to-latest-button {
  position:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width:45px;
  height:45px;
  bottom:40px;
  right:20px;
  background-color:#FF5001;
  color:#FFF;
  border-radius:50px;
  margin-bottom: 2rem;
  z-index: 2;
  cursor: pointer;
  box-shadow: 2px 2px 3px #999;
}

@media (max-width: 31.88em) {
  .scroll-to-latest-button{
    margin-bottom: 5rem;
  }
}
