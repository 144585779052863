.channel-item {
  padding: 0.5rem 1rem;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 35px;
}

.h-\[24px\] {
  height: 22px;
}
