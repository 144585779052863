.NewConversation {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 1rem 0 1rem;
  border-top: 1px solid #ccd0d5;
  background: var(--message-list-bg);
  position: relative;
}

.users-icon-and-heading {
  display: flex;
  justify-content: space-between;
  width: 55% !important;
}

.NewConversation .MessageComposer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  padding: 0 0 1rem 0 !important;
  background: var(--message-list-bg);
}

.NewConversation .Uploaders {
  justify-content: flex-end;
  display: flex;
}

.NewConversation .mentionWrapper .mentionWrapper__control {
  margin: 0;
  width: 96%;
  outline: none;
  min-height: 45px;
  border-radius: 5px;
  font-size: 1rem;
  padding-bottom: 0.8rem;
}

.NewConversation .mentionWrapper .mentionWrapper__control .mentionWrapper__highlighter {
  font-weight: 400;
  outline: 0;
  word-break: break-word;
  border: 0;
  padding: .5rem .6rem 0 .5rem;
  font-size: 13px !important;
  overflow: hidden;
  position: relative;
  overflow-wrap: break-word;
  box-sizing: border-box;
  text-align: start;
  white-space: pre-wrap;
  width: 100%;
}

.NewConversation .mentionWrapper .mentionWrapper__control .mentionWrapper__input {
  outline: 0;
  border: 0;
  padding: .7rem .6rem 0 .5rem;
  background-color: white;
  font-size: 14px !important;
  min-height: 4.2rem;
}

.NewConversation .mentionWrapper__suggestions {
  top: 17.5rem !important;
}

.mentionWrapper__suggestions {
  background-color: #FFF;
  width: 285px;
  height: 200px;
  border: 1px solid #E3E6E8;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: auto;
  z-index: 3 !important;
}

.mentionWrapper__suggestions .mentionWrapper__suggestions__list {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.mentionWrapper .mentionWrapper__suggestions .mentionWrapper__suggestions__item--focused {
  background: #ECEEF1;
  border-radius: 5px;
}

.convo-members-suggestion-item {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: 1fr;
  grid-gap: 0 0.5rem;
  align-items: center;
  padding: 0.5rem 0.5rem;
  background: inherit;
  cursor: pointer;
  grid-template-areas:
  "avatar displayname";
}

.convo-members-suggestion-item .avatar {
  grid-area: avatar;
}

.convo-members-suggestion-item .display-name {
  overflow: hidden;
  text-align: start;
  grid-area: displayname;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.chat-input-container-new-convo {
  flex: 0 0 auto;
  width: 100%;
  position: relative;
  border-radius: 0.25rem;
}

#send-save-btn:disabled {
  color: white;
  background-color: rgba(204, 204, 204, 1);
  cursor: default;
}

.NewConversation.overlay::before {
  content: '';
  min-width: 30vw;
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

@media (max-width: 46.88em) {
  .NewConversation .users-icon-and-heading {
    width: 100% !important;
  }

  .NewConversation .users-icon-and-heading .users-icon-container {
    flex: 1;
  }

  .NewConversation .users-icon-and-heading::after {
    flex: 1;
    content: '';
  }
}
