.border {
  border: 1px solid rgb(237, 237, 242);
}
.w-\[320px\] {
  width: 420px;
}

.relative button.right-0 {
  z-index: 5;
  top: 70px;
}

.flyout-open-button-container {
  top: 75px;
}

.flyout-close-button-container {
  position: absolute;
  right: 0.5rem;
  z-index: 7;
  top: 24px;
}

.flyout-open-button {
  background-color: black;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.flyout-close-button {
  background-color: #FFFFFF;
  border: 1px solid #ECEEF1;
  border-radius: 50%;
  box-shadow: var(--shadow-elevation-medium)
}

:root {
    --shadow-color: 220deg 8% 64%;
    --shadow-elevation-low:
      0px 0.3px 0.3px hsl(var(--shadow-color) / 0.72),
      0px 1px 0.9px -3.7px hsl(var(--shadow-color) / 0.48);
    --shadow-elevation-medium:
      0px 0.3px 0.3px hsl(var(--shadow-color) / 0.67),
      0px 1px 0.9px -1.9px hsl(var(--shadow-color) / 0.52),
      -0.1px 4.9px 4.6px -3.7px hsl(var(--shadow-color) / 0.37);
    --shadow-elevation-high:
      0px 0.3px 0.3px hsl(var(--shadow-color) / 0.75),
      0px 1.4px 1.3px -0.9px hsl(var(--shadow-color) / 0.65),
      -0.1px 4.3px 4px -1.9px hsl(var(--shadow-color) / 0.55),
      -0.2px 10.9px 10.2px -2.8px hsl(var(--shadow-color) / 0.45),
      -0.3px 23.5px 22px -3.7px hsl(var(--shadow-color) / 0.35);
}

@media (max-width: 31.88em) {
  .flyout-close-button-container {
    top: 12px;
  }
}