.channel-browser-container {
  z-index: 3;
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 15rem;
  max-width: 30vw;
  height: 100% !important;
  background: #fff;
}

.ChannelBrowser {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-right: 1.5px solid rgb(237, 237, 242);
}

.input-container input {
  border-bottom: 1px solid #c4c4c6 !important;
}

.input-container {
  position: relative;
}

.input-container .relative {
  width: 100%;
}
.input-container .peer {
  padding-right: 2rem !important;
}

.char-counter {
  position: absolute;
  right: 0.2rem;
  bottom: 0.65rem;
  height: 1rem;
  width: 1rem;
  font-weight: 600;
}

.char-counter.red {
  color: rgb(255, 38, 38);
}

/* custom CSS for channel and conversation items */
.font-weight-600 {
  font-weight: 600;
}

.channel-item.active,
.conversation-item.active {
  background: #ffeee6;
}

.channel-item:not(.active):hover,
.conversation-item:not(.active):hover {
  background: #f7f7f7;
}

p {
  margin: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.settings-btn-container {
  position:fixed;
  display: flex;
  gap: 1rem;
  bottom:0;
}

.settings-btn-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  width:45px;
  height:45px;
  background-color:#FF5001;
  border-radius:50px;
  margin-bottom: 0.5rem;
  z-index: 2;
  cursor: pointer;
  box-shadow: 2px 2px 3px #999;
}

.settings-btn-outline-pendo {
  display: flex;
  justify-content: center;
  align-items: center;
  width:45px;
  height:45px;
  background-color:#FF5001;
  border-radius:50px;
  margin-bottom: 0.5rem;
  z-index: 2;
  cursor: pointer;
  box-shadow: 2px 2px 3px #999;
}


.settings-btn-placeholder {
  margin-top: auto;
  min-height: 4rem;
  padding-left: 1rem;
  background: white;
  width: 100%;
}

.no-margin{
  margin: 0;
}

/* ------------------------- */

.modal-paragraph-text {
  white-space: normal;
}

#modal {
  padding: 2rem !important;
}

@media (max-width: 46.88em) {
  .channel-browser-container {
    display: none;
  }
  .channel-browser-container.display {
    position: absolute !important;
    display: block;
    min-width: 70vw !important;
  }
}

.loggedIn {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #16A085;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  display: inline-block;
  margin-right: 8px;
}

.loggedOut {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #a19b9b;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  display: inline-block;
  margin-right: 8px;
}

.awayStatus {
  display: flex;
  font-size: 16px;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.awayStatusButton {
  display: flex;
  font-size: 15px;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}

.ChannelBrowser .profile-status-container{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChannelBrowser > .profile-status-container > button {
  width: 100%;
  padding: 0 1rem;
}

@media (max-width: 31.88em) {
  .ChannelBrowser .profile-status-container {
    min-width: 100% !important;
  }
}
