.channel-member-list {
  padding: 1rem;
  position: relative;
  overflow: auto;
}

#channel-is-deleted {
  text-align: center;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 8rem;
  padding-top: 2rem;
}

#channel-is-deleted p {
  overflow: visible;
  white-space: inherit;
}