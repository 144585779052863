.icon-container {
  position: relative;
}

.logged-in-reference {
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  background-color: #16A085;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}

.logged-out-reference {
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  background-color: #a19b9b;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}

.reference-list-container {
  padding: 1rem;
}

.reference-list-container .reference-item {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 0 0.5rem;
  align-items: center;
  padding: 0.75rem 1.7rem 0.75rem 0rem;
  background: #fff;
  cursor: pointer;
  grid-template-areas:
    "avatar displayname"
    "avatar status";
}

.reference-list-container .reference-item .avatar {
  grid-area: avatar;
}

.reference-list-container .reference-item .display-name {
  overflow: hidden;
  text-align: start;
  grid-area: displayname;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.reference-list-container .reference-item .status {
  overflow: hidden;
  grid-area: status;
  display: flex;
  align-items: baseline;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reference-list-container .list {
  flex: 0 1;
}

.text-utility-link-info {
  text-decoration: underline;
}

.reference-list-container {
  position: relative;
  overflow: auto;
}
