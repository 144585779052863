.reference-panel-container {
  background-color: rgba(255, 255, 255, 1) !important;
  display: flex;
  flex-direction: column;
  width: 528px;
  height: 698px;
  padding: 1rem;
  border-radius: 0 0 .5rem .5rem;
}

.reference-panel-container .recent-item {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: 1fr;
  grid-gap: 0 0.5rem;
  align-items: center;
  padding: 0.75rem 1.7rem 0.75rem 0rem;
  background: #fff;
  cursor: pointer;
  grid-template-areas: "avatar displayname";
}

.reference-panel-container .result-item {
  display: grid;
  grid-template-columns: min-content 1fr 1fr min-content;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0 0.5rem;
  align-items: center;
  padding: 0.75rem 0.5rem 0.75rem 0rem;
  background: #fff;
  cursor: pointer;
  grid-template-areas:
    "avatar displayname chevronRight"
    "avatar status chevronRight";
}

.reference-panel-container .document-item {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0 0.5rem;
  align-items: center;
  padding: 0.75rem 0.5rem;
  background: #fff;
  cursor: pointer;
  grid-template-areas:
    "avatar displayname"
    "avatar status";
}

.reference-panel-container .result-item:hover {
  background: #f8f9fb;
  border-radius: 10px;
}

.reference-panel-container .result-item .client-list-hover-add-button {
  display: none;
}

.reference-panel-container .result-item:hover .client-list-hover-add-button {
  display: block;
}

.reference-panel-container .result-item .avatar,
.reference-panel-container .document-item .avatar,
.reference-panel-container .recent-item .avatar {
  grid-area: avatar;
}

.reference-panel-container .result-item .chevronRight {
  grid-area: chevronRight;
  text-align: end;
}

.reference-panel-container .result-item .display-name,
.reference-panel-container .document-item .display-name,
.reference-panel-container .recent-item .display-name {
  overflow: hidden;
  text-align: start;
  grid-area: displayname;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.reference-panel-container .result-item .status,
.reference-panel-container .document-item .status,
.reference-panel-container .recent-item .status {
  overflow: hidden;
  grid-area: status;
  display: flex;
  align-items: baseline;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.client-email {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #989a9c;
}

.reference-panel-container .list {
  flex: 1 1 auto;
  overflow: auto;
  width: 100%;
}

.reference-panel-container .accordion-flex {
  flex: 1 1 auto;
  overflow: auto;
}

@media (max-width: 580px) {
  .reference-panel-container {
    height: 500px;
    width: 325px;
    max-width: 528px;
    min-width: 380px;
    max-height: 698px;
    min-height: 500px;
  }
  .reference-panel-container .result-item .client-list-hover-add-button {
    display: block !important;
  }
}

@media (max-width: 420px) {
  .reference-panel-container {
    min-width: 300px;
    min-height: 400px;
  }
}
