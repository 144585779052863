.ReactionPicker svg {
  width: 18px;
  cursor: pointer;
}

.ReactionPicker .ReactionButtons {
  z-index: 5;
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 18px;
  grid-gap: 0.75rem;
  padding: 0.5rem 0.75rem 0.5rem 0.875rem;
  background: #fff;
  border-radius: 4rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
  transform: translateX(-50%);
  color: #898e9e;
}

.ReactionPicker .ReactionButtons.above {
  top: -3rem;
}
.ReactionPicker .ReactionButtons.below {
  bottom: -2rem;
}

.ReactionPicker .ReactionEmojiPicker {
  z-index: 5;
  position: absolute;
  align-items: center;
  font-size: 18px;
  padding: 0;
  background: #fff;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.2);
  transform: translateX(-125%);
  max-width: 100px;
}

.ReactionPicker .ReactionEmojiPicker .emoji-picker-react {
  width: 250px;
  height: 260px;
}

.ReactionPicker .ReactionEmojiPicker.above {
  top: -19.5rem;
}
.ReactionPicker .ReactionEmojiPicker.below {
  bottom: -18.5rem;
}

.ReactionPicker .ReactionButtons.self {
  left: -1.5rem;
}

.ReactionPicker .ReactionEmojiPicker.self {
  left: -5rem;
}

.ReactionPicker .ReactionButtons > button {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  transition: all 0.2s;
}

.ReactionPicker .ReactionButtons > button.active {
  color: var(--primary);
}

.ReactionPicker .ReactionButtons > button:hover {
  opacity: 0.5;
}
