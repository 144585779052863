.EmojiPicker {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    background: transparent;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.EmojiPicker .EmojiPickerContainer {
    z-index: 5;
    position: absolute;
    bottom: 100%;
    right: 1%;
    background: transparent;
}