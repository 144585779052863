#modal h2 {
  overflow: hidden;
  text-overflow: ellipsis;
}

.invite-members-container {
  min-width: 300px;
  margin-top: 50px;
  margin-bottom: 100px;
  height: 70px;
}

.invite-members-container ul {
  padding: 0;
  margin: 0;
}

.border-b {
  border-bottom-style: inset !important;
}
