
.welcome-convo-container {
  display: block;
  flex-direction: column;
  width: 100vw;
  height: 100% !important;
  padding: 0 0.8rem;
  overflow: auto;
  position: relative;
  background: #fff;
}

.welcome-convo-header-container {
  height: auto;
  background: #eceef1;
}

.welcome-first-label {
  height: 76px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #000000;
}

.header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 3px !important;
}

.welcome-second-label {
  height: 56px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  line-height: 22px;
  
  color: #000000;
}

.welcome-staff-button {
  background-color: #FF5000 !important;
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 600;
  line-height: 14px;
}

.welcome-third-label {
  width: 352px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  line-height: 22px;
  margin: 1rem 5rem 0;
  color: #000000;
}

.welcome-convo-footer-container {
  background: #fff;
  padding-left: 10px;
  padding-top: 28px;
  width: 100%;
}

.get-started-text {
  color: var(--grayscale-thryv-black, #231f20);
  font-feature-settings: "clig"off, "liga"off;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.welcome-convo-footer-action-qr {
  height: 188px;
  width: 220px;
  margin-top: 60px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.welcome-convo-footer-action-hello {
  height: 202px;
  width: 249px;
  margin-top: 36px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.welcome-convo-footer-action-channels {
  height: 213px;
  width: 202px;
  margin-top: 36px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.welcome-convo-footer-action-qr img {
  margin-bottom: 36px;
}

.welcome-convo-footer-action-hello img {
  margin-bottom: 22px;
}

.welcome-convo-footer-action-channels img {
  margin-bottom: 22px;
}

.img-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.action-header-first {
  height: auto;
  width: 191px;
  color: var(--grayscale-thryv-black, #231F20);
  text-align: center;
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.action-header-second {
  height: 28px;
  width: 249px;
  color: var(--grayscale-thryv-black, #231F20);
  text-align: center;
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.action-header-third {
  height: 28px;
  width: 202px;
  color: var(--grayscale-thryv-black, #231F20);
  text-align: center;
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.action-message-first {
  width: 220px;
  padding-top: 8px;
  color: var(--grayscale-thryv-steel, #808080);
  text-align: center;
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
}

.action-message-second {
  width: 195px;
  padding-top: 8px;
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  color: var(--grayscale-thryv-steel, #808080);
  text-align: center;
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.action-message-third {
  width: 213px;
  padding-top: 8px;
  color: var(--grayscale-thryv-steel, #808080);
  text-align: center;
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
}



.welcome-video-text {
  width: 330px;
  height: 60px;
  color: var(--grayscale-thryv-night, #4D4D4D);
  text-align: center;
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.video-player {
  height: 14em;
  width: 20em;
  cursor: pointer;
}

.video-player-icon {
  top: 30%;
  left: 40%
}

.welcome-video-text-color {
  color: var(--primary-thryv-orange, #FF5000);
  font-feature-settings: 'clig'off, 'liga'off;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media only screen and (max-width: 575px) {
  .welcome-convo-header-container {
    background: #fff;
  }
}

@media only screen and (min-width:992px) {
  .welcome-convo-footer-container {
    padding-left: 15px;
    padding-top: 20px;
  }
}

@media only screen and (min-width:1200px) {
  
  .welcome-convo-footer-container {
    width: 90%
  }

  .welcome-convo-header-container {
    height: 472px;
  }

  .video-player {
    height: 67%;
    width: 50%;
    cursor: pointer;
  }

  .welcome-labels-container {
    padding-left:60px !important;
  }

  .welcome-convo-footer-container {
    padding-left: 30px;
    padding-top: 28px;
  }

}


@media only screen and (min-width:1400px) {
  .welcome-convo-footer-container {
    width: 80%
  }

  .welcome-convo-footer-container {
    padding-left: 50px;
    padding-top: 48px;
  }
}
