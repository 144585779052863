.conversation-item {
  padding: 0.5rem 1rem;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: .5rem;
}

.conversation-item__avatar-and-count {
  display: flex;
  align-items: center;
}

.conversation-item-avatar {
  height: 32px !important;
  width: 32px !important;
}
.conversation-item-displayName-container {
  display: flex;
}
.conversation-item-displayName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.conversation-item-count-avatar {
  margin-left: -10px;
  font-size: 11px;
  background: #FFF;
  height: 28px;
  width: 28px;
  border: 1px solid #adabab;
}


.icon-container {
  position: relative;
}

.logged-in {
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  background-color: #16A085;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}

.logged-out {
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  background-color: #a19b9b;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}

.multi-conversations {
  border-radius: 30%;
  border: 2px solid #edebeb;
  right: 0;
  position: absolute;
  background-color: white;
}

.customToolTipStyle {
  text-align: center !important;
}