.TeamChatSettingsContainer {
  background: var(--message-list-bg);
}

.settings-height-standard {
  height: 92vh;
}

.settings-height-in-frame {
  height: 100vh;
}

.TeamChatSettingsContainer .NavBar {
  padding: 2rem;
  background: #fff;
}

nav {
  justify-content: flex-start !important;
  border-bottom-style: inset !important;
}

.addButtonSettingUnique:empty {
  display:none;
}

@media (max-width: 780px){
  .hideOnMobile {
      display: none;
  }
}

@media (max-width: 780px){
  .d-inline-flex {
      display: block !important
  } 
}

.subButtonStyle {
  margin-top:10px !important;
}

/* Select dropdown had left padding - this is a bandaid fix, not sure of origin */
.addButtonSettingUnique .relative div div ul {
  padding-left: 0;
}

/* custom style */
.center-align {
  align-items: center;
}
.paragraph-margin-top {
  margin-top: 15px !important;
}

.settings-select-dropdown ul {
  padding-left: 0px;
}