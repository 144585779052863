/* First withModal div: sets overflow, z-index, inset-0, and position: fixed */
#headlessui-portal-root > div > div {
  background-color: rgba(0,0,0, 0.50) !important;
}

/* Second withModal div: sets bg-black, bg-opacity */
#headlessui-portal-root > div > div > div {
  background: unset !important;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#headlessui-portal-root > div > div > div > [class*="sc-"] {
  position: relative;
  border-radius: 50px;
  width: 528px;
}