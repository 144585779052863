* {
  box-sizing: border-box;
}

html,
body {
  --primary: #057AFF;
  --message-list-bg: #F8F9FB;
  position: relative;
  display: block;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;

  /*font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, Lucida Grande,*/
  /*  sans-serif;*/

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

.svg-icon {
  width: 1em;
  height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: currentColor;
}

.svg-icon circle {
  stroke: currentColor;
  stroke-width: 1;
}

.hidden {
  display: none;
}

.skin-tones-list {
  display:none;
}