.composer-item-avatar {
  height: 32px !important;
  width: 32px !important;
}

.icon-container {
  position: relative;
}

.logged-in-composer {
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  background-color: #16a085;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}

.logged-out-composer {
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  background-color: #a19b9b;
  border: 2px solid white;
  bottom: 0;
  right: 0;
  position: absolute;
}

.MessageComposer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: var(--message-list-bg);
}

.MessageComposer .mentionWrapper .mentionWrapper__control {
  margin: 0;
  width: 100%;
  outline: none;
  min-height: 45px;
  border-radius: 5px;
  font-size: 1rem;
  padding-bottom: 0.4rem;
}
.mentionWrapper {
  width: 100%;
}
.MessageComposer
  .mentionWrapper
  .mentionWrapper__control
  .mentionWrapper__highlighter {
  font-weight: 400;
  outline: 0;
  word-break: break-word;
  border: none !important;
  padding: 0.7rem 0.6rem 0 0.5rem;
  background-color: #f8f9fb;
  font-size: 14px !important;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  overflow-wrap: break-word;
  box-sizing: border-box;
  text-align: start;
  white-space: pre-wrap;
  width: 100%;
}
.MessageComposer
  .mentionWrapper
  .mentionWrapper__control
  .mentionWrapper__input {
  outline: 0;
  border: 0;
  padding-left: 8px;
  padding-top: 11px;
  font-size: 14px !important;
  min-height: 3.2rem;
}
.mention-client-activity {
  position: relative;
  z-index: 1;
  color: #2d7aff;
  pointer-events: none;
}
.chat-input-container {
  display: flex;
  gap: 1rem;
  width: 100%;
  position: relative;
  border: 1px solid #ccd0d5;
  border-radius: 0.25rem;
}

.MessageComposer .Uploaders {
  display: flex;
  align-self: flex-start;
  gap: 0.6rem;
  padding: 0.5rem 0.6rem 0.4rem 0;
  position: absolute;
  right: 0;
}

.MessageInputActionToast {
  margin: 0;
  width: 100%;
  padding: 0.7rem;
  height: 40px;
  background: #e3e6e8;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.icon-image {
  height: 24px;
  width: 24px;
}
.icon-file {
  height: 22px !important;
  width: 22px !important;
}
.EmojiPicker {
  padding: 0;
}
.EmojiPicker svg {
  height: 17px;
  width: 20px;
  margin-bottom: 1px;
}
#send-save-btn {
  margin-left: 5px;
  background-color: #ff5000;
  color: white;
  padding: 0.3rem 0.7rem;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 400;
  font-family: Open Sans, sans-serif;
  letter-spacing: 0.5px;
  box-sizing: border-box;
  width: 62px;
}
.mentionWrapper__suggestions {
  margin-top: -14rem !important;
}
.uploaders-limiter {
  width: 11rem;
}

@media (max-width: 31.88em) {
  .chat-input-container {
    flex-direction: column;
    gap: 0;
  }
  .mentionWrapper__control {
    width: 100%;
  }
  .Uploaders {
    margin-left: auto;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    position: static !important;
  }
  .uploaders-box {
    align-self: flex-end;
  }
  .uploaders-limiter {
    display: none;
  }
}
