.Uploader {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.Uploader:first-child {
  margin-top: 1px;
}


.Uploader:hover {
  background: #f4f4f4;
}

.Uploader input {
  visibility: hidden;
  position: absolute;
  top: 100%;
  right: 100%;
}
