.loading-dots {
    text-align: center;
}

.cookiesImageInsideThryv {
    background-color: #F8F9FB;
    height: 100vh;
    text-align: center;
    width:50%;
}

.cookiesImageStandalone {
    background-color: #F8F9FB;
    width:45%;
    margin:auto;
    height: 100vh;
}