.ChannelHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: .8rem;
  padding: 1.5rem;
  background: var(--message-list-bg);
  border-bottom: 1.5px solid rgb(237, 237, 242);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
}

.ChannelHeader h3 {
  margin: 0;
  font-size: 1.17rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.users-icon-container {
  cursor: pointer;
  position: relative;
  display: none;
}
.icon-users {
  height: 22px;
  width: 22px;
}
.icon-pill {
  height: 22px;
  width: 22px;
  position: absolute;
  top: -15px;
  right: -20px;
}

.channel-header__name-member-count {
  display: flex;
  align-items: baseline;
  gap: .6rem;
  overflow: hidden;
}

.member-count {
  display: flex;
  align-items: center;
  gap: .2rem;
}

.member-count svg {
  margin-bottom: .2rem;
}

.channel-header__invite-ellipsis {
  display: flex;
  gap: 1rem;
}

@media (max-width: 46.88em) {
  .member-count {
    display: none;
  }
  .users-icon-container {
    display: block;
  }
  .ChannelHeader h3 {
    font-size: 1rem;
  }
}

@media (max-width: 25.375em) {
  .ChannelHeader {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 
    'users-icon invite-ellipsis'
    'channel-name channel-name';
    gap: .5rem;
  }
  .users-icon-container {
    grid-area: users-icon;
  }
  .channel-header__name-member-count {
    grid-area: channel-name;
    justify-self: center;
  }
  .channel-header__invite-ellipsis {
    grid-area: invite-ellipsis;
    justify-self: end
  }
}


.border-b {
  border-bottom-style: inset !important;
}
