.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ref-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
}

.content-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
}

.ref-panel-title-bar-avatar {
    margin: auto 0.5rem auto auto;
}

@media (max-width: 580px) {
    .ref-title {
        font-size: 12px;
    }
    .ref-nav ul>div{
        padding: 0.8rem;
    }
    .ref-nav ul{
        padding: 0;
    }
    .nav-icon svg {
        height: 20px;
    }
}
